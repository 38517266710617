/* Import the base Shoelace stylesheet: */
@import "@shoelace-style/shoelace/dist/themes/light.css";

/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


@media (prefers-color-scheme: dark) {
  :root {
    --body-background: rgb(22, 163, 74);
    --main-background: rgb(15 23 42);
    --body-color: rgb(148 163 184);
    --heading-color: rgb(226 232 240);
    --action-color: rgb(226 232 240);
    --main-border-style: solid;
    --main-border-width: 2px;
    --main-border-color: rgb(148 163 184);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --body-background: rgb(22, 163, 74);
    --main-background: rgb(249, 250, 251);
    --body-color: rgb(107, 114, 128);
    --heading-color: rgb(17, 24, 39);
    --action-color: rgb(17, 24, 39);
    --main-border-style: none;
    --main-border-width: 0px;
  }
}

html {
  background-position: top;
  background-repeat: no-repeat;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
/*
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.5;
*/
  position: relative;
  min-height: 100%;
}

a {
  color: var(--action-color);
  text-decoration: none;
  text-decoration-color: #ffb088;
}

.clouds {
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1920' height='300' preserveAspectRatio='none' viewBox='0 0 1920 300'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1149%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c39 C 48%2c51.8 144%2c99.4 240%2c103 C 336%2c106.6 384%2c57 480%2c57 C 576%2c57 624%2c100.8 720%2c103 C 816%2c105.2 864%2c63.4 960%2c68 C 1056%2c72.6 1104%2c129.6 1200%2c126 C 1296%2c122.4 1344%2c57 1440%2c50 C 1536%2c43 1584%2c95.2 1680%2c91 C 1776%2c86.8 1872%2c41.4 1920%2c29L1920 300L0 300z' fill='rgba(20%2c 83%2c 45%2c 1)'%3e%3c/path%3e%3cpath d='M 0%2c238 C 192%2c227 576%2c185.4 960%2c183 C 1344%2c180.6 1728%2c217.4 1920%2c226L1920 300L0 300z' fill='rgba(22%2c 163%2c 74%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1149'%3e%3crect width='1920' height='300' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

.light-icon sl-icon-button::part(base) {
    color: rgb(249 250 251);
}

.light-icon sl-icon-button::part(base):hover {
    color: rgb(2 132 199);
} 

.gradient {
  @apply bg-gradient-to-b from-sky-100 via-sky-100 to-sky-400 dark:from-slate-900 dark:via-slate-900 dark:to-slate-600;
}

ul {
  list-style: disc;
  list-style-position: outside;
  margin-left: 1.25rem;
}


a:hover {
  text-decoration: underline;
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

body > header {
  /*
  margin: 1rem;
  text-align: center;
  */
}

body > header img {
  /*
  display: inline-block;
  width: 400px;
  max-width: 100%;
  */
}

body > nav ul {
  /*
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
  */
}

body > nav a {
  text-decoration: none;
}

main {
  /*
  background-color: var(--main-background);
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  border-style: var(--main-border-style);
  border-width: var(--main-border-width);
  border-color: var(--main-border-color);
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
  */
}

footer {
  /*
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
  */
}

hr {
  /*
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
  */
  color: var(--main-background);
}

.mobile_nav {
  transform: translate(-100%, 0);
  transition: all 0.3s ease-in-out;
  -webkit-overflow-scrolling: touch;
}

.mobile_nav_button span {
  transition-duration: 0s;
  transition-delay: 0.2s;
  display: inline-block;
  top: -0.4rem;
  width: 2.0rem;
  height: 0.2rem;
  background-color: rgb(45, 48, 52);
  position: relative; 
}

@media (prefers-color-scheme: dark) {
  .mobile_nav_button span {
    background-color: rgb(226 232 240);
  }
}

.mobile_nav_button span::after, .mobile_nav_button span::before {
  transition-delay: 0.2s, 0s;
  transition-duration: 0.2s;
  transition-property: margin, transform;
  display: block;
  content: '';
  position: absolute;
  width: 2.0rem;
  height: 0.2rem;
  background-color: rgb(45, 48, 52);
}

@media (prefers-color-scheme: dark) {
  .mobile_nav_button span::after, .mobile_nav_button span::before {
    background-color: rgb(226 232 240);
  }
}

.mobile_nav_button span::before {
  margin-top: -0.5rem;
}

.mobile_nav_button span::after {
  margin-top: 0.5rem;
}

.mobile_nav_checkbox:checked ~ .mobile_nav {
  transform: translate(0, 0);
}

.mobile_nav_checkbox:checked ~ .mobile_nav_button span {
  background-color: rgba(0,0,0,0.0);
}

.mobile_nav_checkbox:checked ~ .mobile_nav_button span::before, .mobile_nav_checkbox:checked ~ .mobile_nav_button span::after {
  transition-delay: 0s, 0.2s;
  margin-top: 0; 
}

.mobile_nav_checkbox:checked ~ .mobile_nav_button span::before {
  transform: rotate(45deg);
}

.mobile_nav_checkbox:checked ~ .mobile_nav_button span::after {
  transform: rotate(-45deg);
}

.mobile_nav_checkbox:checked ~ .customize_button {
  display: none;
}

.top_nav_icon, .top_nav_button {
  display: none;
}

.header--scrolled {
  box-shadow: 0 0 3rem rgba(0, 0, 0, .1);
}

